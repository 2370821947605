import Restaurante from "../../model/Restaurante";
import FormUtils from "../../utils/FormUtils";
import CpfCnpjUtils from "../../utils/CpfCnpjUtils";
import {RestauranteHorarioValidator} from "./RestauranteHorarioValidator";
import TelefoneUtils from "../../utils/TelefoneUtils";

export default async function RestauranteValidator(entidade: Restaurante, error: (mensagem: string) => void): Promise<boolean> {

    if (!entidade.nomeEstabelecimento) {
        FormUtils.invalidarComponente(error, "Nome do estabelecimento é obrigatório.");
        return false;
    }

    if (!entidade.razaoSocial) {
        FormUtils.invalidarComponente(error, "Razão social é obrigatório.");
        return false;
    }

    if (!entidade.cpfCnpj) {
        FormUtils.invalidarComponente(error, "CPF/CNPJ é obrigatório.");
        return false;
    }

    if (!CpfCnpjUtils.isCpfCnpj(entidade.cpfCnpj)) {
        FormUtils.invalidarComponente(error, "CPF/CNPJ inválido.");
        return false;
    }

    if (!entidade.enderecoCompleto) {
        FormUtils.invalidarComponente(error, "Endereço completo é obrigatório.");
        return false;
    }

    if (entidade.whatsapp && !TelefoneUtils.isTelefone(entidade.whatsapp)) {
        FormUtils.invalidarComponente(error, "Formato do WhatsApp inválido. Deve ser por exemplo (44) 99999-9999");
        return false;
    }

    if (!entidade.latitude || !entidade.longitude) {
        FormUtils.invalidarComponente(error, "O endereço não foi corretamente selecionado.");
        return false;
    }

    return RestauranteHorarioValidator(entidade, error);
}