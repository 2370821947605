import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import styles from './EditView.module.css';

import {Divider} from 'primereact/divider';
import {Toolbar} from "primereact/toolbar";
import {Button} from "primereact/button";
import EditViewProps from "./EditViewProps";
import {Ajax} from "../utils/Ajax";
import {MensagemContext} from "../context/MensagemContext";
import {MensagemContextType} from "../context/MensagemContextType";

function EditView(props: EditViewProps) {
    const { uuid } = useParams();
    const navigate = useNavigate();
    const {info, error} = useContext(MensagemContext) as MensagemContextType;
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (uuid !== undefined) {
            Ajax.of()
                .error(error)
                .obterPorUuid(props.id, uuid, (data) => {
                    if (props.entidadeUseStateFunction !== undefined)
                        props.entidadeUseStateFunction(data);

                    if (props.afterLoadEntidade !== undefined) {
                        props.afterLoadEntidade(data);
                    }
                });
        }
    }, [uuid]);

    const voltarInicio = () => {
        navigate(`/${props.id}`);
    }

    const botoes = (
        <>
            <Button icon="pi pi-save" className="mr-2" label={"Salvar"} title={"Salvar"} raised size={"large"}
                    loading={loading}
                    onClick={async (): Promise<void> => executeOnSalvar()} />

            <Button className="mr-2" label={props.cancelarLabel ? props.cancelarLabel : "« Voltar"} title={props.cancelarLabel ? props.cancelarLabel : "« Voltar"} link size={"large"}
                    onClick={voltarInicio} />
        </>
    );

    const executeOnSalvar = async (): Promise<void> => {
        try {
            if (props.onValidationHandler !== undefined) {
                if (!await props.onValidationHandler()) {
                    return;
                }
            }

            if (props.onSalvarHandler !== undefined) {
                setLoading(true);

                await props.onSalvarHandler(() => {
                    setLoading(false);
                });

                voltarInicio();
            } else {
                setLoading(true);

                await Ajax.of()
                        .info(info)
                        .error(error)
                        .salvarRegistro(props.id, props.entidade, () => {
                            setLoading(false);
                            voltarInicio();
                        }, () => {
                            setLoading(false);
                        });
            }
        } catch (e) {
            error("" + e);
        }
    }

    return (
        <>
            <div className="card">
                <div className={styles.title}>{ props.title }</div>

                <Divider />

                <div className={styles.espacamento}>
                    { props.children }
                </div>

                <Divider />

                <Toolbar start={ botoes } />
            </div>
        </>
    );
}

export default EditView;