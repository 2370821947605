import React, {forwardRef, useState} from 'react';
import rest from "../../../utils/EnvConfig";
import {AutoComplete, AutoCompleteSelectEvent} from "primereact/autocomplete";
import styles from "../texto/Texto.module.css";
import GoogleMapsAutoCompleteProps from "./GoogleMapsAutoCompleteProps";

class GoogleResponse {
    description: string;
    place_id: string;

    constructor(description: string, place_id: string) {
        this.description = description;
        this.place_id = place_id;
    }
}

const GoogleMapsAutocomplete = forwardRef((props: GoogleMapsAutoCompleteProps, inputRef?: any) => {
    const [suggestions, setSuggestions] = useState<GoogleResponse[]>([]);

    const completeMethod = async (event: any) => {
        const value = event.query;

        if (value.trim() !== '') {
            try {
                const response = await fetch(`${rest.server}googlemaps/autocomplete?input=${value}`);
                const data = await response.json();
                setSuggestions(data.predictions.map((p: any) => new GoogleResponse(p.description, p.place_id)));
            } catch (error) {
                console.error('Erro ao pesquisar pelo endereço:', error);
            }
        } else {
            setSuggestions([]);
        }
    }

    const onSelectHandler = async (suggestion: AutoCompleteSelectEvent) => {
        const googleResponse: GoogleResponse = suggestion.value;

        try {
            const response = await fetch(`${rest.server}googlemaps/geocode?place_id=${googleResponse.place_id}`);
            const data = await response.json();
            const { lat, lng } = data.results[0].geometry.location;

            setSuggestions([]);

            if (props.afterSelectAddress) {
                props.afterSelectAddress(googleResponse.description, lat, lng);
            }
        } catch (error) {
            console.error('Erro ao pesquisar a geolocalização:', error);
        }
    };

    return (
        <div className={`${styles.espacamento} p-fluid grid`}>
            <div className={props.fullSize ? "field col-12 md:col-12" : "field col-12 md:col-4"}>
                <label className={styles.label} htmlFor={props.id}>{props.label}</label>
                <span>
                    <AutoComplete
                        value={props.value}
                        suggestions={suggestions}
                        field={"description"}
                        style={{width: `${props.size}`}}
                        tooltip={props.tooltip ? props.tooltip : undefined}
                        tooltipOptions={props.tooltipPosition ? {position: props.tooltipPosition} : undefined}
                        maxLength={props.maxLength === undefined ? 255 : props.maxLength}
                        completeMethod={completeMethod}
                        placeholder={"Ex: Av. Brasil, 123, Centro, Maringá, PR "}
                        delay={500}
                        onSelect={onSelectHandler}
                        onChange={props.onChangeHandler}
                        onKeyDown={props.onKeyDownHandler}
                    />
                </span>
            </div>
        </div>
    );
});

export default GoogleMapsAutocomplete;