import {Entidade} from "./Entidade";
import Restaurante from "./Restaurante";

export default class RestauranteDiaDiferencial extends Entidade {

    restaurante?: Restaurante;
    data?: Date;
    fechado: boolean = false;
    horaInicio?: string;
    horaFim?: string;

}