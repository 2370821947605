export default abstract class TelefoneUtils {

    private constructor() {
    }

    static isTelefone = (telefone: string): boolean => {
        const valorLimpo: string = telefone
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("-", "")
            .replaceAll(" ", "")
            .trim();

        if (valorLimpo.length < 10) {
            return false;
        }

        if (valorLimpo.length === 10) {
            const re = (/^(\d{2})(\d{4})(\d{4})$/);
            return re.test(valorLimpo);
        } else {
            const re = /^(\d{2})(\d{5})(\d{4})$/;
            return re.test(valorLimpo);
        }
    }

}