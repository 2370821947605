import * as React from "react";
import {forwardRef} from "react";

import styles from './Botao.module.css';
import BotaoProps from './BotaoProps';
import {Button} from "primereact/button";

const Botao = forwardRef((props: BotaoProps, inputRef?: any) => {
    return (
        <>
            <div className={`${styles.espacamento}`}>
                <Button id={props.id} label={props.label}
                        icon={props.icon}
                        size={props.size}
                        style={props.style}
                        loading={props.loading}
                        onClick={props.onClickHandler}/>
            </div>
        </>
    );
});

export default Botao;