import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';

import styles from './ListView.module.css';

import {Toolbar} from 'primereact/toolbar';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {DataTable} from 'primereact/datatable';
import ListViewProps from "./ListViewProps";
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
import {Column, ColumnBodyOptions} from "primereact/column";
import {Ajax} from "../utils/Ajax";
import {MensagemContext} from "../context/MensagemContext";
import {MensagemContextType} from "../context/MensagemContextType";


function ListView(props: ListViewProps) {
    const {info, error} = useContext(MensagemContext) as MensagemContextType;
    const [resultado, setResultado] = useState<any[]>();
    // const [botaoExtraIndex, setBotaoExtraIndex] = useState<number>(0);
    const [pesquisa, setPesquisa] = useState<string>("");
    const navigate = useNavigate();
    const [loadingGrid, setLoadingGrid] = useState<boolean>(false);
    const [loadingRemover, setLoadingRemover] = useState<boolean>(false);
    const [loadingPesquisar, setLoadingPesquisar] = useState<boolean>(false);

    let botaoExtraIndex = 0;

    useEffect(() => {
        setLoadingGrid(true);

        if (props.restCustom) {
            Ajax.of()
                .error(error)
                .obterTodosCustom(props.restCustom, (data) => {
                    setResultado(data);
                    setLoadingGrid(false)
                }, () => setLoadingGrid(false));
        } else {
            Ajax.of()
                .error(error)
                .obterTodos(props.id, (data) => {
                    setResultado(data);
                    setLoadingGrid(false);
                }, () => setLoadingGrid(false));
        }
    }, []);

    const acoesComp = (
        <>
            <Button icon="pi pi-file" className="mr-2" title={"Novo registro"}
                    label={"Novo"} raised size={"large"}
                    onClick={() => navigate(`/${props.id}/novo`)}/>

            {props.botoesPrincipaisAdicionais !== undefined &&
                (props.botoesPrincipaisAdicionais.map((botao, botaoIndex) => {
                    return (botao)
                }))}
        </>
    );

    const confirmarRegistro = async (rowData: any) => {
        setLoadingRemover(true);

        await Ajax.of()
            .error(error)
            .info(info)
            .removerRegistro(props.id, rowData.uuid, (data) => {
                if (resultado !== undefined) {
                    setResultado(resultado.filter(r => r.uuid !== rowData.uuid))
                }
                setLoadingRemover(false);
            }, () => setLoadingRemover(false));
    };

    const removerRegistroConfirm = async (rowData: any) => {
        confirmDialog({
            message: 'Tem certeza que deseja remover esse registro?',
            header: 'Atenção',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: "Sim",
            rejectLabel: "Não",
            async accept() {
                await confirmarRegistro(rowData);
            }
        });
    };

    const pesquisaComp = (
        <span className="p-input-icon-left">
            <InputText placeholder="Pesquisa" className={styles.pesquisa}
                       value={pesquisa} onChange={(e: any) => setPesquisa(e.target.value)}/>
            <Button icon="pi pi-search" className={`${styles.espacamentoBotoes} mr-2`} title={"Pesquisar"} raised
                    loading={loadingPesquisar}
                    onClick={async () => executeOnPesquisar()}/>
        </span>
    );

    const executeOnPesquisar = async () => {
        setLoadingPesquisar(true);
        setLoadingGrid(true);

        try {
            if (props.onPesquisarHandler !== undefined) {
                await props.onPesquisarHandler(() => {
                    setLoadingPesquisar(false);
                    setLoadingGrid(false);
                });
            }

            let pesquisaMap: any = {};

            React.Children.map(props.children, async (child) => {
                if (React.isValidElement(child)) {
                    const chave = child.props.field;
                    pesquisaMap[chave] = pesquisa;
                }
            });

            await Ajax
                .of()
                .error(error).obterPorFiltro(props.id, pesquisaMap,
                    (data) => {
                        setResultado(data);
                        setLoadingPesquisar(false);
                        setLoadingGrid(false);
                    }, () => {
                        setLoadingPesquisar(false);
                        setLoadingGrid(false);
                    });
        } catch (e) {
            error(`${e}`);
            setLoadingPesquisar(false);
            setLoadingGrid(false);
        }
    }

    const botoesGridTemplate = (rowData: any, options: ColumnBodyOptions) => {
        return (
            <div className="flex align-items-center">
                <Button icon="pi pi-pencil" className={`${styles.espacamentoBotoes} mr-2`} title={"Editar"} raised
                        disabled={props.disableEditar} visible={props.visibleEditar}
                        onClick={async () => navigate(`/${props.id}/editar/${rowData.uuid}`)}/>

                <Button icon="pi pi-trash" className={`${styles.espacamentoBotoes} mr-2`} title={"Remover"}
                        severity="danger" raised disabled={props.disableExcluir} visible={props.visibleExcluir}
                        onClick={async () => await removerRegistroConfirm(rowData)}/>

                {props.botoesAdicionais !== undefined &&
                    (props.botoesAdicionais(rowData, options.rowIndex).map((botao, botaoIndex) => {
                        return (botao)
                    }))}
            </div>
        );
    };

    // @ts-ignore
    const globalFilters: string[] = props.colunas?.map((item) => item.caminhoAtributo);

    return (
        <>
            <div className="card">
                <div className={ styles.title }>{ props.title }</div>

                <Toolbar start={ acoesComp } end={ pesquisaComp } />

                <DataTable value={ resultado } tableStyle={{ minWidth: '50rem' }}
                           dataKey="id"
                           loading={loadingGrid}
                           globalFilterFields={globalFilters}
                           paginator rows={10} rowsPerPageOptions={[10, 25, 50, 100]}
                           removableSort
                           stripedRows emptyMessage={"Nenhum registro encontrado"}>

                    <Column body={botoesGridTemplate} header={""} style={{ width: `${props.widthBotoes ? props.widthBotoes : '140px'}`}} />

                    { props.children }
                </DataTable>

                <ConfirmDialog />
            </div>
        </>
    );
}

export default ListView;