import * as React from "react";
import {forwardRef, useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import styles from "../mascara/TextoMascara.module.css";
import TelefoneBRProps from "./TelefoneBRProps";

const TelefoneBR = forwardRef((props: TelefoneBRProps, inputRef?: any) => {
    const [phone, setPhone] = useState('');

    useEffect(() => {
        setPhone(props.value);
    }, [props.value]);

    const formatPhoneNumber = (value: any) => {
        // Remove todos os caracteres não numéricos
        const cleanedValue = value.replace(/\D/g, '');

        // Limita o comprimento máximo
        const limitedValue = cleanedValue.slice(0, 11);

        // Formatação em tempo real
        if (limitedValue.length <= 2) {
            // Adiciona parênteses no DDD
            return limitedValue.length > 0 ? `(${limitedValue}` : '';
        }

        if (limitedValue.length <= 6) {
            // Adiciona fechamento de parênteses e início do número
            return `(${limitedValue.slice(0, 2)}) ${limitedValue.slice(2)}`;
        }

        if (limitedValue.length <= 7) {
            // Adiciona fechamento de parênteses e início do número
            return `(${limitedValue.slice(0, 2)}) ${limitedValue.slice(2, 7)}`;
        }

        if (limitedValue.length <= 8) {
            // Adiciona fechamento de parênteses e início do número
            return `(${limitedValue.slice(0, 2)}) ${limitedValue.slice(2, 8)}`;
        }

        if (limitedValue.length <= 9) {
            // Adiciona fechamento de parênteses e início do número
            return `(${limitedValue.slice(0, 2)}) ${limitedValue.slice(2, 9)}`;
        }

        if (limitedValue.length <= 10) {
            // Formato para telefone fixo: (99) 9999-9999
            return limitedValue.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }

        // Formato para celular: (99) 99999-9999
        return limitedValue.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
    };

    const handlePhoneChange = (e: any) => {
        const inputValue = e.target.value;
        const formattedValue = formatPhoneNumber(inputValue);
        setPhone(formattedValue);
        props.onChangeHandler(formattedValue);
    };

    return (
        <>
            <div className={`${styles.espacamento} p-fluid grid`}>
                <div className={props.fullSize ? "field col-12 md:col-12" : "field col-12 md:col-4"}>
                    <label className={styles.label} htmlFor={props.id}>{props.label}</label>
                    <span>
                        <InputText
                            type="tel"
                            id={props.id} value={phone}
                            ref={inputRef !== undefined ? inputRef : null}
                            tooltip={props.tooltip ? props.tooltip : undefined}
                            tooltipOptions={props.tooltipPosition ? {position: props.tooltipPosition} : undefined}
                            aria-describedby={`${props.id}-message`}
                            style={{width: `${props.size}`}}
                            required={props.required}
                            disabled={props.disabled}
                            maxLength={15}
                            onBlur={props.onBlurHandler}
                            onFocus={props.onFocusHandler}
                            onChange={(e: any) => {handlePhoneChange(e);}}
                            placeholder={props.placeholder}
                        />
                    </span>
                </div>
            </div>
        </>
    );
});

export default TelefoneBR;