import * as React from "react";

import styles from './Header.module.css';

function Header() {
    return (
        <nav className={`${styles.navbar}`}>
            O que abre hoje
        </nav>
    );
}

export default Header;