import ListView from "../../component/ListView";
import {Column} from "primereact/column";
import useToken from "../../hook/useToken";
import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {TipoUsuario} from "../../model/TipoUsuario";
import rest from "../../utils/EnvConfig";
import moment, {Moment} from "moment/moment";
import Diaria from "../../model/Diaria";
import DinheiroUtils from "../../utils/DinheiroUtils";
import HorarioUtils from "../../utils/HorarioUtils";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import styles from "../restaurante/Restaurante.module.css";
import {Dialog} from "primereact/dialog";
import {DataTable} from "primereact/datatable";
import DiariaCandidato from "../../model/DiariaCandidato";
import {Ajax} from "../../utils/Ajax";
import {MensagemContext} from "../../context/MensagemContext";
import {MensagemContextType} from "../../context/MensagemContextType";

function DiariaList() {
    const {token} = useToken();
    const {error, info} = useContext(MensagemContext) as MensagemContextType;
    const navigate = useNavigate();
    const horarioUtils = new HorarioUtils();
    const [candidatosDialog, setCandidatosDialog] = useState<boolean>(false);
    const [candidatosLoading, setCandidatosLoading] = useState<boolean>(false);
    const [diariaSelecionada, setDiariaSelecionada] = useState<Diaria>();
    const [candidatosDiaria, setCandidatosDiaria] = useState<DiariaCandidato[]>([]);

    useEffect(() => {
        if (token && token.tipo !== TipoUsuario.ADMINISTRADOR) {
            navigate("/sem-permissao");
            return;
        }
    }, []);

    const statusDiariaTemplate = (diaria: Diaria): string => {
        return diaria.status ? "Ativo" : "Inativo";
    };

    const dataDiariaTemplate = (diaria: Diaria): string => {
        if (diaria.data) {
            const novaData: Moment = moment(diaria.data, "YYYY-MM-DD");
            return novaData.format("DD/MM/YYYY");
        }
        return "";
    };

    const quantidadeHorasTemplate = (diaria: Diaria) => {
        if (diaria.horaInicio && diaria.horaFim) {
            return `${horarioUtils.calcularDiferencaEntreHoras(diaria.horaInicio, diaria.horaFim).toFixed(2)}h`;
        }
        return "0h"
    };

    const valorDiariaTemplate = (diaria: Diaria) => {
        if (diaria.valorPagamento) {
            return DinheiroUtils.formatToDinheiro(diaria.valorPagamento);
        }
        return "A combinar";
    };

    const restaurantesDiariaTemplate = (diaria: Diaria) => {
        if (diaria.restaurantes) {
            return diaria.restaurantes.map(r => r.nomeEstabelecimento)
                        .reduce((v, acc) => v + ", " + acc);
        }
        return "Nenhum restaurante";
    }

    const carregarCandidatosPorDiaria = (diaria: Diaria) => {
        setCandidatosDiaria([]);
        setCandidatosLoading(true);
        setDiariaSelecionada(diaria);
        setCandidatosDialog(true);

        Ajax.of()
            .error(error)
            .obterTodosCustom(`${rest.diariaCandidato}/${rest.candidatosPorDiaria}/${diaria.uuid}`,
            (data) => {
                setCandidatosLoading(false);
                setCandidatosDiaria(data);
            },
           (error: any) => setCandidatosLoading(false));
    }

    const dataCandidatosTemplate = (candidato: DiariaCandidato): string => {
        if (candidato.data) {
            const novaData: Moment = moment(candidato.data, "YYYY-MM-DD");
            return novaData.format("DD/MM/YYYY");
        }
        return "";
    };

    const botoesExtra = (entidade: Diaria, rowIndex?: number): React.JSX.Element[] => {
        return [
            (
                <>
                    <Button icon={PrimeIcons.USERS} className={`${styles.espacamentoBotoes} mr-2`} raised
                            title={"Candidatos"}
                            onClick={(event: any) => carregarCandidatosPorDiaria(entidade)}
                            severity="success" />
                </>
            )];
    }

    return (
        <>

            {token && token.tipo === TipoUsuario.ADMINISTRADOR && (
                <>
                    <Dialog header={"Candidatos"} visible={candidatosDialog} style={{width: '50vw'}}
                            onHide={() => {
                                if (!candidatosDialog) return;
                                setCandidatosDialog(false);
                            }}>
                        <div className="grid">
                            <div className="col-2">
                                <div className="p-1 font-bold">
                                    Restaurante(s):
                                </div>
                            </div>
                            <div className="col-10">
                                <div className="p-1">
                                    {diariaSelecionada?.restaurantes!
                                        .map(r => r.nomeEstabelecimento)
                                        .reduce((v, acc) => v + ", " + acc)}
                                </div>
                            </div>

                            <div className="col-2">
                                <div className="p-1 font-bold">
                                    Data:
                                </div>
                            </div>
                            <div className="col-10">
                                <div className="p-1">
                                    {moment(diariaSelecionada?.data, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                </div>
                            </div>

                            <div className="col-2">
                                <div className="p-1 font-bold">
                                    Observação:
                                </div>
                            </div>
                            <div className="col-10">
                                <div className="p-1">
                                    {diariaSelecionada?.observacao}
                                </div>
                            </div>
                        </div>

                        <br />

                        <DataTable value={candidatosDiaria} tableStyle={{ minWidth: '50rem' }}
                                   loading={candidatosLoading}
                                   emptyMessage={"Nenhum registro encontrado"}>
                            <Column field="nomeUsuarioFirebase" header="Candidato"></Column>
                            <Column field="emailUsuarioFirebase" header="E-mail"></Column>
                            <Column field="data" header="Data" body={dataCandidatosTemplate}></Column>
                            <Column field="comentario" header="Comentário"></Column>
                        </DataTable>
                    </Dialog>

                    <ListView title={"Diária"}
                              botoesAdicionais={botoesExtra}
                              id={`${rest.diaria}`}>
                        <Column field="restaurantes" header="Restaurantes" sortable body={restaurantesDiariaTemplate}/>
                        <Column field="tipoServico.nomeServico" header="Tipo do serviço" sortable/>
                        <Column field="data" header="Data" sortable body={dataDiariaTemplate}/>
                        <Column field="quantidadeHoras" header="Quantidade de horas" body={quantidadeHorasTemplate} sortable />
                        <Column field="valorPagamento" header="R$ Valor" sortable body={valorDiariaTemplate} />
                        <Column field="status" header="Situação" sortable body={statusDiariaTemplate} />
                    </ListView>
                </>
            )}
        </>
    );
}

export default DiariaList;